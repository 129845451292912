import React from 'react'
import { useTrans } from 'hooks'
import { MenuItemType } from 'utils/types'
import { getIcon } from 'utils/icons'
import { Button, FormField, FormInput, FormCheckbox, WContainer } from 'components/atoms'
import styles from './PreFooter.module.sass'
import { useSelector } from 'react-redux'
import { Formik } from 'formik'
import * as yup from 'yup'
import { useRouter } from 'next/router'
import useGTM from '../../../hooks/useGTM'

const validationSchema = (t) =>
  yup.object().shape({
    email: yup
      .string()
      .email(t("Il formato dell'email dev'essere valido"))
      .nullable()
      .required(t('Questo campo non può essere vuoto')),

    tos: yup
      .boolean()
      .required(t('Questo campo non può essere vuoto'))
      .oneOf([true], t('Devi accettare i Termini e le Condizioni')),
  })

interface Props {
  className?: string
}

const PreFooter = ({ className }: Props) => {
  const t = useTrans()

  const router = useRouter()
  const { trackingGAevent } = useGTM()

  const { menu } = useSelector((state) => state.menu)
  const preFooterMenu = menu?.find((item) => item.key === 'prefooter')

  return (
    <div className={`${styles.footer} ${className}`}>
      <WContainer variant="sm">
        <div className={styles.layout}>
          <div className={styles.content}>
            {preFooterMenu && (
              <>
                <p className={styles.title}>{preFooterMenu.name}</p>
                <div className={styles.list}>
                  {preFooterMenu.children?.length > 0 &&
                    preFooterMenu.children.map((item) => (
                      <div className={styles.item} key={item.pk}>
                        <span className={styles.icon}>{getIcon(item.style)}</span>
                        <div
                          className={styles.description}
                          dangerouslySetInnerHTML={{ __html: item.description }}
                        />
                      </div>
                    ))}
                </div>
              </>
            )}
          </div>
          <div className={styles.newsletterBox}>
            <p className={styles.newsletterTitle}>{t('Iscriviti alla newsletter')}</p>
            <Formik
              validationSchema={validationSchema(t)}
              initialValues={{ email: '', tos: false }}
              onSubmit={(values) => router.push(`/registrazione?email=${values.email}`)}
            >
              {(props) => (
                <>
                  <FormField label={t('La tua email')} color="white" layout="full">
                    <FormInput
                      onBlur={props.handleBlur('email')}
                      placeholder={t('Email')}
                      value={props.values.email || ''}
                      onChange={props.handleChange('email')}
                    />
                  </FormField>
                  <FormField
                    layout="full"
                    status={props.touched.tos ? (props.errors.tos ? 'error' : 'success') : null}
                    errorMessage={props.errors.tos}
                  >
                    <FormCheckbox
                      id="nl_termini"
                      color="white"
                      size="sm"
                      label={t('Accetto i termini e condizioni')}
                      checked={props.values?.tos}
                      onChange={props.handleChange('tos')}
                    />
                  </FormField>
                  <Button
                    type="submit"
                    label={t('Voglio iscrivermi alla newsletter')}
                    className={styles.newsletterSubmit}
                    disabled={!props.isValid}
                    onClick={() => {
                      trackingGAevent('4', 'newsletter_subscription', 'click')
                      props.handleSubmit()
                    }}
                  />
                </>
              )}
            </Formik>
          </div>
        </div>
      </WContainer>
    </div>
  )
}

export default PreFooter
